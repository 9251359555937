<template>
 <Loading v-show="show" />

  <div class="breadcrumb-banner-area">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="breadcrumb-text">
            <h1 class="text-left">{{ Department }}</h1>
            <div class="breadcrumb-bar">
              <ul class="breadcrumb text-left">
                <li>
                  <router-link to="/">{{ home }}</router-link>
                </li>
                <li>{{ Department }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Events Details Area Start-->
  <div class="news-details-area section-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-12 col-12">
          <div class="news-details-content">
            <div class="single-latest-item">
              <img v-bind:src="imgUniv" />
              <div class="single-latest-text">
                <h3 id="TitleUniv"></h3>
                <p id="DetailsUniv"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 col-12">
          <div class="sidebar-widget">
            <div class="single-sidebar-widget">
              <h4 class="title">{{ Department }}</h4>
              <div class="recent-content">
                <div
                  v-for="Events in Events"
                  :key="Events.postId"
                  class="recent-content-item"
                >
                  <router-link
                    :to="{
                      name: 'DepartmentDetails-with-BrnId-And-Type-And-id',
                      params: {
                        type: 'Univ.Faculty.Activity',
                        id: Events.postID,
                        BrnId: Events.brnID,
                      },
                    }"
                    ><img
                      @click="getDataEvents(Events.postID, $route.params.type)"
                      class="img_left"
                      v-bind:src="'https://api2.yg-u.net/images/post/' + Events.postImage"
                    />
                  </router-link>
                  <div class="recent-text">
                    <p @click="getDataEvents(Events.postID, $route.params.type)">
                      <router-link
                        :to="{
                          name: 'DepartmentDetails-with-BrnId-And-Type-And-id',
                          params: {
                            type: 'Univ.Faculty.Activity',
                            id: Events.postID,
                            BrnId: Events.brnID,
                          },
                        }"
                      >
                        {{ Events.postTitle }}
                      </router-link>

                      <!--   <router-link
                class="button-default"
                :to="{
                  name: 'Event-with-id-And-Type',
                  params: {
                    brn: $route.params.id,
                    name: 'الاقسام',  
                    type: 'Univ.Faculty.Activity',
                    id: event.postID,
                  },
                }"
              > -->
                    </p>
                    <!-- <p>   {{ Events.postSubTitle }}</p> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="single-sidebar-widget">
              <ul class="tags">
                <li>
                  <router-link
                    :to="{
                      name: 'Department-with-BrnId-And-Type',
                      params: {
                        type: 'Univ.Faculty.Activity',
                        BrnId: $route.params.BrnId,
                      },
                    }"
                  >
                    {{ BtnMore }}</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End of Events Details Area-->
</template>
<script>
import Loading from './Loading'

import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
   components:{
Loading
  },
  data() {
    return {
      show: true,
      Events: [],
      Title: "",
      Details: "",
      imgUniv: "",
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      home: "",
      Department: "",
      BtnMore: "",
    };
  },
  methods: {
      getapis() {
      var self = this;
      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
       document.getElementById("langfilewebsites")?.remove();
        self.getDataEvents(this.$route.params.id,this.$route.params.type)

      } else {
        self.translate = self.EnTranslat;
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "../../../../style-ltr.css");
        document.head.appendChild(link1);
      }

      self.home = self.translate[0]["HeaderAndFooter"]["Home"];
      self.Department = self.translate[0]["Home"]["Department"];
      self.BtnMore = self.translate[0]["Home"]["BtnDetials"];

      var bodyFormData2 = new FormData();
      bodyFormData2.append("check", "getPost");
      bodyFormData2.append("Lang", localStorage.getItem("lang"));
      bodyFormData2.append("PostId", "");
      bodyFormData2.append("FbrnId", this.$route.params.BrnId);
      bodyFormData2.append("Type", this.$route.params.type);
      bodyFormData2.append("ParentId", "NTROOT0");
      bodyFormData2.append("PostName", "");
      bodyFormData2.append("Pno", "10");
      axios({
        method: "post",
        url: "https://api2.yg-u.net/our_team/getPost",
        data: bodyFormData2,
      }).then(function (response) {
        self.Events = response.data;

        document.getElementById("VueMainJsNewTouch")?.remove();
        let recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute("src", "/js/main.js");
        recaptchaScript.id = "VueMainJsNewTouch";
        document.head.appendChild(recaptchaScript);

        setTimeout(() => {
          self.show = false;
          document.getElementById('YG_U').setAttribute('style','display:none !important')

        }, 100);
      });
    },
    getDataEvents(PostID, Type) {
      var self = this;
      self.show = true;
      window.scroll(0, 0);
      //translate
      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
      } else {
        self.translate = self.EnTranslat;
      }

      self.home = self.translate[0]["HeaderAndFooter"]["Home"];
      self.Department = self.translate[0]["Home"]["Department"];
      self.BtnMore = self.translate[0]["Home"]["BtnDetials"];

      var bodyFormData1 = new FormData();
      bodyFormData1.append("check", "getPostById");
      bodyFormData1.append("PostId", PostID);
      bodyFormData1.append("Lang", localStorage.getItem("lang"));
      bodyFormData1.append("FbrnId", this.$route.params.BrnId);
      bodyFormData1.append("Type", Type);
      bodyFormData1.append("ParentId", "NTROOT0");
      bodyFormData1.append("PostName", "");
      bodyFormData1.append("Pno", "1");
      axios({
        method: "post",
        url: "https://api2.yg-u.net/our_team/getPost",
        data: bodyFormData1,
      })
        .then(function (response) {
          var arr = response.data;
          if (arr.length != 0) {
            self.imgUniv =
              "https://api2.yg-u.net/images/post/" + response.data[0]["postImage"];
            document.getElementById("TitleUniv").innerText =
              response.data[0]["postTitle"];
            document.getElementById("DetailsUniv").innerHTML =
              response.data[0]["postDetails"];
          }

          self.show = false;
        })
        .catch(function (response) {
          console.log("error", response);
          self.show = false;
        });
    },
  
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yg-u.net/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);
        self.getapis();
      });
    } else self.getapis();
  },
};
</script>

<style scoped>
.title {
  width: 100% !important;
  text-align: right !important ;
  background: #fff !important ;
  position: relative !important ;
}
.single-sidebar-widget .tags li a {
  background: #3498db none repeat scroll 100% 0;
  border: 1px solid #3498db;
  color: #ffffff;
  float: right;
  margin-bottom: 13px;
  margin-left: 6px;
  padding: 11px 35px;
  position: absolute;
}
.img_left {
  height: 70px !important;
  widows: 70px im !important;
}
.recent-text p {
  font-size: 15px;
  line-height: 23px;
}
.single-latest-item img {
  width: 870px;
  height: 435px;
}
.news-details-content .single-latest-text {
  padding: 28px 20px 0px;
  text-align: justify;
}
</style>
